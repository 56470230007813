// export const baseurl = 'https://stg-pro-kpi-api.viserx.dev/api'
// export const baseurl = 'http://pro-kpi-api.test/api'
// export const baseurl = 'http://kpi-module.test/api'

// export const baseurl = 'https://api-kpi.seoviserx.com/api'


export const baseurl = 'https://api-pro-kpi.viserx.dev/api'
export const PUSHER_APP_ID = '1543662'
export const PUSHER_APP_KEY = 'df38110fa36d67c5ab79'
export const PUSHER_APP_SECRET = '6c4f266e03e180acf1c2'
export const PUSHER_APP_CLUSTER = 'ap1'